import Countdown from 'react-countdown'
import { ShareIcon,
        GlobeIcon, 
        NewspaperIcon, 
        AtSymbolIcon} from '@heroicons/react/outline'
import { StatBar } from '../stats/StatBar'
import { Histogram } from '../stats/Histogram'
import { GameStats } from '../../lib/localStorage'
import { shareStatus,
        twitterStatus, } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  STATISTICS_TITLE,
  GUESS_DISTRIBUTION_TEXT,
  NEW_WORD_TEXT,
  SHARE_TEXT,
  LINK_TO_GSW,
} from '../../constants/strings'
import { MigrationIntro } from '../stats/MigrationIntro'
import { ENABLE_MIGRATE_STATS } from '../../constants/settings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  solution: string
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  handleMigrateStatsButton: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
}

export const StatsModal = ({
  isOpen,
  handleClose,
  solution,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  handleMigrateStatsButton,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
}: Props) => {
  if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title={STATISTICS_TITLE}
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={gameStats} />
        {ENABLE_MIGRATE_STATS && (
          <MigrationIntro handleMigrateStatsButton={handleMigrateStatsButton} />
        )}
      </BaseModal>
    )
  }
  return (
    <BaseModal
      title={STATISTICS_TITLE}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
        {GUESS_DISTRIBUTION_TEXT}
      </h4>
      <Histogram
        gameStats={gameStats}
        isGameWon={isGameWon}
        numberOfGuessesMade={numberOfGuessesMade}
      />
      {(isGameLost || isGameWon) && (
        <div className="mt-5 sm:mt-6 dark:text-white">
          <div>
            <h5>{NEW_WORD_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
            />
          </div>
          <div>
            <button
              type="button"
              className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gsw-blue text-base font-medium text-white hover:bg-gsw-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => {
                shareStatus(
                  solution,
                  guesses,
                  isGameLost,
                  isHardMode,
                  isDarkMode,
                  isHighContrastMode,
                  handleShareToClipboard
                )
              }}
            >
              <ShareIcon className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
              {SHARE_TEXT}
            </button>
            <button
              type="button"
              className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gsw-blue text-sm font-medium text-white hover:bg-gsw-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              //href=`%LINK_TO_GSW(solution)%`
              //target="_blank"
              onClick={() => {

               window.open(`${LINK_TO_GSW(solution)}`,'_blank');   
              
              }}
            >
              <GlobeIcon className="h-8 w-8 mr-2 cursor-pointer dark:stroke-white" />
              Learn More at GeoScienceWorld
            </button>
            <button
              type="button"
              className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gsw-blue text-sm font-medium text-white hover:bg-gsw-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {

               window.open('https://share.hsforms.com/1IqUpyP0ZTXSn0C3W0usXFgctwp6','_blank');   
              
              }}
            >
              <NewspaperIcon className="h-8 w-8 mr-2 cursor-pointer dark:stroke-white" />
              Join the GeoScienceWorld Newsletter
            </button>
            <button
              type="button"
              className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gsw-blue text-base font-medium text-white hover:bg-gsw-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => {

                window.open(twitterStatus(solution, guesses, isGameLost, isHardMode, isDarkMode, isHighContrastMode,),'_blank');  
              }}
            >
              <AtSymbolIcon className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
              Tweet Your Score
            </button>
          </div>
        </div>
      )}
      {ENABLE_MIGRATE_STATS && (
        <div>
          <hr className="mt-4 -mb-4 border-gray-500" />
          <MigrationIntro handleMigrateStatsButton={handleMigrateStatsButton} />
        </div>
      )}
    </BaseModal>
  )
}
