export const WORDS = [
  'mineral',
  'epidote',
  'aeolian',
  'edifice',
  'variety',
  'vacuole',
  'aphyric',
  'felsite',
  'tillite',
  'benthic',
  'biotite',
  'erratic',
  'sorting',
  'olivine',
  'hardpan',
  'perlite',
  'bioherm',
  'diabase',
  'stratum',
  'glacial',
  'granite',
  'nunatak',
  'mantled',
  'erosion',
  'rhizome',
  'bedrock',
  'terrain',
  'isotope',
  'polygon',
  'isograd',
  'thalweg',
  'coquina',
  'neritic',
  'geodesy',
  'aureole',
  'bivalve',
  'blowout',
  'outcrop',
  'coarser',
  'clastic',
  'rosette',
  'breccia',
  'vesicle',
  'melange',
  'fissure',
  'history',
  'aquifer',
  'diorite',
  'caldera',
  'drumlin',
  'seriate',
  'terrane',
  'orogeny',
  'veinlet',
  'crinoid',
  'plateau',
  'contact',
  'bedding',
  'cutbank',
  'seismic',
  'exhumed',
  'picrite',
  'isthmus',
  'calcite',
  'pelitic',
  'sapping',
  'zeolite',
  'diamict',
  'fissile',
  'outwash',
  'elastic',
  'fluvial',
  'geology',
  'igneous',
  'meander',
  'arenite',
  'surface',
  'yardang',
  'crystal',
  'soluble',
  'estuary',
  'lapilli',
  'angular',
  'aphanic',
  'hogback',
  'syenite',
  'boxwork',
  'alkalic',
  'brittle',
  'coesite',
  'tektite',
  'massive',
  'pocosin',
  'terrace',
  'driblet',
  'casting',
  'tabular',
  'lignite',
  'compact',
  'miocene',
  'lowland',
  'sulfide',
  'remnant',
  'moraine',
  'plastic',
  'friable',
  'boulder',
  'silting',
  'plumose',
  'glacier',
  'spatter',
  'lakebed',
  'caliche',
  'kyanite',
  'gypsite',
  'parting',
  'fanning',
  'pendant',
  'molasse',
  'lamella',
  'mollusk',
  'welding',
  'rhombic',
  'petrify',
  'hornito',
  'ductile',
  'tumulus',
  'rebound',
  'silicic',
  'pothole',
  'azimuth',
  'sulfate',
  'siltite',
  'steptoe',
  'element',
  'sectile',
  'fibrous',
  'hillock',
  'suevite',
  'apatite',
  'halides',
  'pluvial',
  'volcano',
  'enclave',
  'oolitic',
  'calving',
  'thermal',
  'tripoli',
  'karstic',
  'fenster',
  'synform',
  'mudflow',
  'lithify',
  'deposit',
  'neogene',
  'tombolo',
  'micrite',
  'asphalt',
  'channel',
  'pangaea'
]
